import React from "react";

// styled
import { Layout, Image, Text } from "./loading.styled";

//---------------------------------------------------------
const Loading: React.ReactNode = (
  <Layout>
    <Image src="assets/main-character.gif" alt="no loading.gif" />
    <Text>Loading...</Text>
  </Layout>
);

export default Loading;