import { createSlice } from "@reduxjs/toolkit";

interface InitialStateType {
  open: boolean;
  loading: boolean;
  confirm: boolean;
  success: boolean;
  faceOff: boolean;
}

const initialState: InitialStateType = {
  open: false,
  loading: false,
  confirm: false,
  success: false,
  faceOff: false
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setConfirm: (state, action) => {
      state.confirm = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setFaceOff: (state, action) => {
      state.faceOff = action.payload;
    },
  },
});

export const modalReducer = modalSlice.reducer;
export const modalActions = modalSlice.actions;