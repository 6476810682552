import { createGlobalStyle } from "styled-components";

import { Variables } from "./variable";
import { GV, declearStyleVariables } from "utils/style.util";

//-------------------------------------------------------------------------------------

const GlobalStyle = createGlobalStyle`
	:root {
		${declearStyleVariables(Variables)}
	}

	* {
		padding: 0px;
		margin: 0px;
		box-sizing: border-box;
	}

	html, body {
 	 scroll-behavior: smooth;
	}

	body {
        background: ${GV("bg")};
		font-family: ${GV("font")};
		color: ${GV("color")};
	}

	h1, h2, h3, h4, h5, h6 {
		margin: 0;
	}
	
	p {
		margin:0;
	}
	
	a {
		text-decoration:none; 
		color:inherit; 
		cursor:pointer;
	}

	::-webkit-scrollbar {
		width: 6px;
	}

	::-webkit-scrollbar-track {
		background: transparent;
	}

	::-webkit-scrollbar-thumb {
		background: #ffffff30;
		border-radius: 10px;
	}

	.custom-modal .ant-modal-content {
		background: #000;
	}

	.custom-modal .ant-modal-close-x {
		color: white
	}

	@keyframes pulse {
		0% {
			transform: scale(1);
			opacity: 1;
		}
		50% {
			transform: scale(1.2); /* Adjust scale value to control the intensity of the pulse */
			opacity: 0.7; /* Adjust opacity to make the pulse more pronounced */
		}
		100% {
			transform: scale(1);
			opacity: 1;
		}
	}

	.image-wrapper a {
		animation: pulse 2s infinite;
	}
`;

export default GlobalStyle;
