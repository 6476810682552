import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";

// styled
import { Layout, BGImage, AppContainer, Message } from "./layout.styles";

// components
import { AppHeader } from "./Header";
import Loading from "components/Loading";
import ModalContainer from "components/ModalContainer";

// Redux
import { Provider } from "react-redux";
import { store } from "../../redux/store";

// Antd
import { ConfigProvider } from "antd";
import { GV } from "utils/style.util";

// Toast
import { ToastContainer } from "react-toastify";

//-------------------------------------------------------

const AppLayout: React.FC = () => {
  return (
    <Suspense fallback={Loading}>
      <ConfigProvider theme={{ token: { colorPrimary: GV("orange") } }}>
        <Provider store={store}>
          <Layout>
            <BGImage />
            <AppHeader />
            <AppContainer>
              <Outlet />
              <ModalContainer />
            </AppContainer>
            <Message onClick={() => {}}>
              <img src="assets/message.png" alt="" />
            </Message>
          </Layout>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Provider>
      </ConfigProvider>
    </Suspense>
  );
};

export default AppLayout;
