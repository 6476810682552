import { createSlice } from "@reduxjs/toolkit";

interface InitialStateType {
  combat: string[];
  personality: { trait_type: string; value: number }[];
}

const initialState: InitialStateType = {
  combat: [],
  personality: [
    { trait_type: "Honor", value: 50 },
    { trait_type: "Bravery", value: 50 },
    { trait_type: "Pride", value: 50 },
    { trait_type: "Intelligence", value: 50 },
    { trait_type: "Patience", value: 50 },
    { trait_type: "Social", value: 50 },
    { trait_type: "Stubborn", value: 50 },
    { trait_type: "Perception", value: 50 },
  ],
};

const metadataSlice = createSlice({
  name: "metadata",
  initialState,
  reducers: {
    setCombat: (state, action) => {
      state.combat = action.payload;
    },
    setPersonality: (state, action) => {
      state.personality = action.payload;
    },
    updatePersonality: (state, action) => {
      const { traitType, value } = action.payload;
      const updatedPersonality = state.personality.map((trait) =>
        trait.trait_type === traitType ? { ...trait, value } : trait
      );
      state.personality = updatedPersonality;
    },
  },
});

export const metadataReducer = metadataSlice.reducer;
export const metadataActions = metadataSlice.actions;
