import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Antd
import { Modal } from "antd";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../../redux/modal";

// styled
import {
  Layout,
  ModalTitle,
  ModalSubTitle,
  ModalDesc,
  DateText,
  DateItem,
  ModalButton,
  ButtonDesc,
} from "./modal.styled";

//---------------------------------------------------------
const ModalContainer = () => {
  const router = useNavigate();
  const { combat } = useSelector((state: any) => state.metadata);
  const { open, loading, confirm, success, faceOff } = useSelector(
    (state: any) => state.modal
  );

  const dispatch = useDispatch();

  const getDate = () => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const currentDate = new Date();
    const monthIndex = currentDate.getMonth();
    const day = currentDate.getDate();

    const formattedDate = `${months[monthIndex]} ${day}`;
    return formattedDate;
  };

  return (
    <Modal
      open={open}
      onCancel={() => dispatch(modalActions.setOpen(false))}
      footer={null}
      closable={true}
      maskClosable={false}
      className="custom-modal"
    >
      <Layout>
        {loading ? (
          <>
          <img src="assets/maskin.gif" alt=""/>
          <ModalTitle>Loading...</ModalTitle>
          </>
        ) : success ? (
          <>
            <ModalTitle>CONGRATULATIONS</ModalTitle>
            <ModalSubTitle>
              YOU NOW HAVE A MINT PASS IN YOUR WALLET
            </ModalSubTitle>
            <ModalDesc>
              You have pre-ordered and AI generative TOMMY T avatar with unique
              personality traits and combat skills to fight in the dream channel
              game
            </ModalDesc>
            <DateText>The min is set down for</DateText>
            <DateItem>{getDate()}</DateItem>
            <ModalButton
              onClick={() => {
                router("/");
                dispatch(modalActions.setOpen(false));
              }}
            >
              Play Special Release
            </ModalButton>
            <ButtonDesc>Play a Special Release of the Game now</ButtonDesc>
          </>
        ) : combat.length === 0 ? (
          <>
            <ModalTitle>CONFIRM</ModalTitle>
            <ModalSubTitle>Select Combat Traits must now be set.</ModalSubTitle>
            <ModalDesc>
              You have to confirm your Tommy Turbo AIs <br />
              skills and personality.
            </ModalDesc>
            <ModalButton
              onClick={() => {
                router("/combat");
                dispatch(modalActions.setOpen(false));
              }}
            >
              Select Combat Traits
            </ModalButton>
          </>
        ) : (
          <>
            <ModalTitle>CONFIRMED</ModalTitle>
            <ModalSubTitle>
              Your Tommy Turrbo AIs skills and personality have been confirmed
            </ModalSubTitle>
            <ModalDesc>
              You now need to MINT a AI PERSONALITY TOKEN with this information
              embedded
            </ModalDesc>
            <ModalButton
              onClick={() => {
                router("/mint");
                dispatch(modalActions.setConfirm(true));
                dispatch(modalActions.setOpen(false));
              }}
            >
              Mint
            </ModalButton>
          </>
        )}
      </Layout>
    </Modal>
  );
};

export default ModalContainer;
