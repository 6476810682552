import React from "react";
import { useNavigate } from "react-router-dom";

// styled
import {
  AppHeaderWrapper,
  LogoContainer,
  ButtonWrapper,
  MobileMenu,
  HeaderTitle,
  LogoWrapper,
  TribeWrapper,
  HeaderInfo,
  HeaderData,
} from "./header.styled";

// assets
import { BsBell } from "react-icons/bs";
import { IoMenu } from "react-icons/io5";
import { MdOutlineRemoveRedEye } from "react-icons/md";

//----------------------------------------------------------------------------

export const AppHeader: React.FC = () => {
  const router = useNavigate();

  return (
    <AppHeaderWrapper>
      <LogoContainer>
        <ButtonWrapper onClick={() => {}}>explore</ButtonWrapper>
        <MobileMenu onClick={() => {}}>
          <IoMenu size={30} color="rgba(212, 212, 212, 1)" />
        </MobileMenu>
        <LogoWrapper
          onClick={() => {
            router("/");
          }}
        >
          <img src="/assets/logo.png" alt="" />
        </LogoWrapper>
      </LogoContainer>
      <HeaderTitle>TOMMY TURBO</HeaderTitle>
      <TribeWrapper>
        <div>
          <span>TRIBE:</span>
          <img src="/assets/maskin.gif" alt="" width={39} height={39} />
        </div>
        <div>
          <span>RANK: </span>
          <p>#14</p>
        </div>
        <div>
          <button>VR LINK</button>
          <img src="/assets/coin.png" alt="" width={40} height={40} />
        </div>
      </TribeWrapper>
      <HeaderInfo>
        <HeaderData>
          <div>
            DZZZ <span>435</span>
          </div>
          <div>
            GAZE <span>25</span>
          </div>
          <div>
            <MdOutlineRemoveRedEye size={20} color="#fff" />
            <span>25:45</span>
          </div>
        </HeaderData>
        <span>
          <BsBell size={20} color="#fff" />
        </span>
        <img src="/assets/character.gif" alt="" width={46} height={46} />
      </HeaderInfo>
    </AppHeaderWrapper>
  );
};
