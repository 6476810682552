import styled from "styled-components";
import { GV } from "utils/style.util";

export const Layout = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalTitle = styled.div`
  width: 100%;

  font-family: Kanit;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0.05em;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
`;
export const ModalSubTitle = styled.div`
  width: 80%;

  margin-top: 10px;

  font-family: Yantramanav;
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 16px;
  }
`;
export const ModalDesc = styled.div`
  width: 80%;
  font-family: Yantramanav;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;

  margin-top: 24px;

  @media screen and (max-width: 768px) {
    width: 100%;

    font-size: 14px;
  }
`;
export const DateText = styled.div`
  font-family: Yantramanav;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  margin-bottom: 8px;
  margin-top: 24px;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;
export const DateItem = styled.div`
  background: #ff5c0026;
  font-family: Yantramanav;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;

  padding: 4px 24px 4px 24px;
`;
export const ModalButton = styled.div`
  margin-top: 24px;

  cursor: pointer;

  width: 100%;
  max-width: 300px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${GV("orange")};

  font-family: Kanit;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.16em;
  text-align: center;
`;
export const ButtonDesc = styled.div`
  font-family: Yantramanav;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #d9d9d9;
  margin-top: 8px;
`;
