import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

// helmet
import { Helmet, HelmetProvider } from "react-helmet-async";

// style
import GlobalStyle from "./style/globalStyle";

// toast
import "react-toastify/dist/ReactToastify.css";

//-----------------------------------------------------------------------

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Audiowide&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Yantramanav:wght@100;300;400;500;700;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <GlobalStyle />
      <App />
    </HelmetProvider>
  </React.StrictMode>
);
