import styled from "styled-components";
import { GV } from "utils/style.util";

export const Layout = styled.div`
  position: relative;

  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;

  background: ${GV("bg")};
  overflow-x: hidden;

  z-index: 0;
`;

export const BGImage = styled.div`
  width: 100vw;
  height: 100vh;

  position: absolute;
  left: 0px;
  top: 0px;

  background: url("/assets/bg.png") no-repeat;
  background-position: center;
  background-size: cover;

  z-index: 1;
`;

export const AppContainer = styled.div`
  flex: 1;
  width: 100%;

  display: flex;
  flex-direction: column;

  z-index: 2;
`;

export const Message = styled.div`
  position: fixed;

  bottom: 30px;
  right: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 45px;
  height: 45px;

  border-radius: 10px;

  img {
    width: 100%;
    height: 100%;
  }

  z-index: 999;
`;
