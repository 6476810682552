import { createBrowserRouter, Navigate } from "react-router-dom";

// constants
import _ROUTERS from "constants/route";

// layout
import { AppLayout } from "layouts";

// pages
import { MainPage, CombatPage, PersonalityPage, MintPage, LostPage, StoryPage } from "pages";

//---------------------------------------------------------------------------------

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      { path: _ROUTERS._HOME, element: <MainPage /> },
      { path: _ROUTERS._COMBAT, element: <CombatPage /> },
      { path: _ROUTERS._PERSONALITY, element: <PersonalityPage /> },
      { path: _ROUTERS._Mint, element: <MintPage /> },
      { path: _ROUTERS._Story, element: <StoryPage /> },
    ],
  },
  { path: "404", element: <LostPage /> },
  {
    path: "*",
    element: <Navigate to="/404" />,
  },
]);

export { router };