import React from "react";

// router
import { RouterProvider } from "react-router-dom";
import { router } from "router";

//-----------------------------------------------------------

const App: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default App;
