import { lazy } from "react";

//-------------------------------------------------------------------
const MainPage = lazy(() => import("./main"));
const CombatPage = lazy(() => import("./combat"));
const PersonalityPage = lazy(() => import("./personality"));
const MintPage = lazy(() => import("./mint"));
const LostPage = lazy(() => import("./lost"));
const StoryPage = lazy(() => import("./story"));

export { MainPage, CombatPage, PersonalityPage, MintPage, LostPage, StoryPage };
