import styled from "styled-components";

//------------------------------------------------

export const Layout = styled.div`
  position: relative;

  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #000;

  overflow: hidden;
`;


export const Image = styled.img`
  width: 300px;
  height: 300px;
`;

export const Text = styled.div`
  margin-top: 20px;

  font-size: 40px;
  color: white;
`;
