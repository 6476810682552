import styled from "styled-components";
import { GV } from "utils/style.util";

export const AppHeaderWrapper = styled.div`
  height: 52px;

  background: ${GV("sub-bg")};

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 12px;

  z-index: 2;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  height: 32px;
  width: 95px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${GV("orange")};

  border: 1px solid ${GV("orange")};

  @media screen and (max-width: 1440px) {
    display: none;
  }
`;

export const LogoWrapper = styled.div`
  width: 121px;
  height: 52px;

  margin-left: 8px;

  img {
    width: 100%;
    height: 100%;
  }

  cursor: pointer;
`;

export const HeaderTitle = styled.div`
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 1);

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const MobileMenu = styled.div`
  display: none;
  @media screen and (max-width: 1440px) {
    display: block;
  }
`;

export const TribeWrapper = styled.div`
  display: flex;
  align-items: center;

  & > div {
    margin-right: 24px;

    display: flex;
    align-items: center;

    font-family: Yantramanav;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.16em;
    color: #ffffff;

    span {
      margin-right: 12px;
    }

    p {
      font-weight: 900;
    }

    button {
      height: 32px;
      width: 89px;

      border: none;
      outline: none;

      color: #b1eaf6;
      background: transparent;

      font-size: 12px;
      font-weight: 700;
      margin-right: 8px;
      letter-spacing: 0.16em;

      cursor: pointer;
    }
  }
  @media screen and (max-width: 1440px) {
    display: none;
  }
`;

export const HeaderInfo = styled.div`
  display: flex;
  align-items: center;

  span {
    height: 20px;
    margin-right: 10px;
  }
`;

export const HeaderData = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    align-items: center;

    height: 20px;
    margin-right: 30px;

    font-size: 12px;
    font-weight: 700;
    color: #fff;

    span {
      display: flex;
      align-items: center;

      color: ${GV("orange")};
      margin-right: 0px;
    }

    span:last-child {
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 1440px) {
    display: none;
  }
`;
